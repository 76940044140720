import React from 'react';
import './Demat.css'; // Make sure to create a corresponding CSS file
import Zerodha from '../Images/Zerodha.png';
import choice from '../Images/choice.jpg';
import mastertrust from '../Images/mastertrust.png';

const Demat = () => {
    const zerodhaAccountURL = "https://zerodha.com/?c=BKF711&s=CONSOLE";
    const masterTrustAccountURL = "https://www.mastertrust.co.in/products-pricing/MT_CR007860@wp";
    const choiceAccountURL = "https://choiceindia.com/open-free-demat-account?refercode=QzAwMTQyNTQ=&source=Q0hPSUNFX0NPTk5FQ1Q=";
  return (
    <div className="demat-container">
      <h1 className="demat-title">Explore Demat Account Brokers</h1>
      <div className="brokers-container">
             {/* Zerodha */}
             <div className="broker-card">
          <img src={Zerodha} alt="Zerodha Logo" className="broker-logo" />
          <a href={zerodhaAccountURL} className="open-account-btn" target="_blank" rel="noopener noreferrer">Open Account</a>
        </div>

        {/* MasterTrust */}
        <div className="broker-card">
          <img src={mastertrust} alt="MasterTrust Logo" className="broker-logo" />
          <a href={masterTrustAccountURL} className="open-account-btn" target="_blank" rel="noopener noreferrer">Open Account</a>
        </div>

    
        {/* Choice Equity */}
        <div className="broker-card">
          <img src={choice} alt="Choice Equity Logo" className="broker-logo" />
          <a href={choiceAccountURL} className="open-account-btn" target="_blank" rel="noopener noreferrer">Open Account</a>
        </div>
        {/* Add more brokers as needed */}
      </div>
    </div>
  );
};

export default Demat;
