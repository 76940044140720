import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './HomePage.css';

// Assume these components exist
import Footer from './Footer';
import TestimonialCarousel from './TestimonialCarousel';
import Header from './Header';

const HomePage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [stats, setStats] = useState({ clients: 0, courses: 0, satisfaction: 0 });

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setStats(prev => ({
        clients: prev.clients < 80 ? prev.clients + 1 : prev.clients,
        courses: prev.courses < 50 ? prev.courses + 1 : prev.courses,
        satisfaction: prev.satisfaction < 98 ? prev.satisfaction + 1 : prev.satisfaction
      }));
    }, 20);
    return () => clearInterval(interval);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Header />
      <div className="home-page">

        <motion.section 
          className="hero"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h1>Master the Market with Rahar Stock Market Academy</h1>
          <p>Unlock your financial potential through expert-led courses and cutting-edge analysis tools.</p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => scrollToSection('course-cards')}
          >
            Explore Courses
          </motion.button>
        </motion.section>

        <motion.section 
          className="features"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h2>Why Choose Rahar Stock Academy?</h2>
          <div className="feature-grid">
            {[
              { icon: '📊', title: 'Real-time Market Insights', desc: 'Access live market data and expert analysis.' },
              { icon: '🤖', title: 'AI-Powered Predictions', desc: 'Leverage machine learning for smarter trades.' },
              { icon: '👨‍🏫', title: 'Expert Instructors', desc: 'Learn from seasoned market professionals.' },
              { icon: '🎯', title: 'Personalized Learning', desc: 'Tailored courses to match your goals and experience.' }
            ].map((feature, index) => (
              <motion.div 
                key={index} 
                className="feature-card"
                whileHover={{ scale: 1.05 }}
              >
                <span className="feature-icon">{feature.icon}</span>
                <h3>{feature.title}</h3>
                <p>{feature.desc}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className="stats"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <div className="stat-item">
            <h3>{stats.clients}+</h3>
            <p>Happy Clients-Completed Course</p>
          </div>
          <div className="stat-item">
            <h3>{stats.courses}+</h3>
            <p>Clients with Us for Over 1 Years</p>
          </div>
          <div className="stat-item">
            <h3>{stats.satisfaction}%</h3>
            <p>Client Satisfaction</p>
          </div>
        </motion.section>

        <motion.section 
          className="courses-preview"
          id="course-cards"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h2>Our Top Courses</h2>
          <div className="course-cards">
            {[
              { title: 'Technical Analysis Mastery', level: 'Intermediate', duration: '4 weeks' },
              { title: 'Fundamental Stock Valuation', level: 'Advanced', duration: '6 weeks' },
              { title: 'Options Trading Strategies', level: 'Expert', duration: '8 weeks' }
            ].map((course, index) => (
              <motion.div 
                key={index} 
                className="course-card"
                whileHover={{ scale: 1.05 }}
              >
                <h3>{course.title}</h3>
                <p>Level: {course.level}</p>
                <p>Duration: {course.duration}</p>
                <Link to={`/syllabus`}>Learn More</Link>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className="testimonials"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h2>What Our Students Say</h2>
          <TestimonialCarousel />
        </motion.section>

        <motion.section 
          className="cta cta-section"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h2>Ready to Start Your Journey?</h2>
          <p>Join thousands of successful traders who have transformed their financial future with Rahar Stock Academy.</p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to="/signup">Get Started Today</Link>
          </motion.button>
        </motion.section>

        <Footer />
      </div>
    </>
  );
};

export default HomePage;
