import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'


import Header from './components/Header'
import ContactPage from './components/ContactPage'
import Services from './components/Services';
import About from './components/About'
import HomePage from './components/HomePage'
import Demat from './components/Demat'
import Syllabus from './components/Syllabus'
import StudyOptions from './components/StudyOptions'
import Testimonials from './components/Testimonials'
import WhatsAppButton from './components/WhatsAppButton '
import MessagesPage from './components/MessagesPage '

const App = () => {
  return (
    <Router>
   
        
  
        <Route component={HomePage} exact path="/" />
      <Route component={ContactPage} exact path="/Contact" />
      <Route component={Services}   exact path="/services"  />
        <Route component={Header} exact path="/Header" />
        <Route component={About} exact path="/About" />
        <Route component={Syllabus} exact path="/Syllabus" />
        <Route component={StudyOptions} exact path="/StudyOptions" />
        <Route component={Testimonials} exact path="/Testimonials" />
        <Route component={Demat} exact path="/open-demat" />
        <Route component={MessagesPage} exact path="/Messages" />
     
        
       <WhatsAppButton/>
      
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
