import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaYoutube, FaInstagram, FaWhatsapp, FaSearch, FaBars, FaTimes } from 'react-icons/fa';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);

    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.menu-toggle')) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setSearchOpen(false);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    setIsMenuOpen(false);
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="top-bar">
          <div className="social-icons">
            <a href="https://wa.me/+917009362253" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp"><FaWhatsapp /></a>
            <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></a>
          </div>
        </div>
        <div className="main-header">
          <button className="menu-toggle" onClick={toggleMenu}>
            <FaBars />
          </button>
          <div className="logo">
            <Link to="/">Rahar Stock Market Academy</Link>
          </div>
          <nav className={`navigation ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
            <button className="close-menu" onClick={toggleMenu}>
              <FaTimes />
            </button>
            <ul>
              <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
              <li><Link to="/syllabus" onClick={toggleMenu}>Course</Link></li>
              <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
              <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
          </nav>
          <div className="header-actions">
            <button className="search-toggle" onClick={toggleSearch}>
              <FaSearch />
            </button>
            <Link to="/contact" className="cta-button">Get Started</Link>
          </div>
        </div>
        <div className={`search-bar ${searchOpen ? 'open' : ''}`} ref={searchRef}>
          <input type="text" placeholder="Search..." />
          <button><FaSearch /></button>
        </div>
      </div>
    </header>
  );
}

export default Header;
