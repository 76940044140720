import React from 'react';
import './StudyOptions.css'; // Make sure to create a corresponding CSS file

const StudyOptions = () => {
  return (
    <div className="study-options-container">
      <h1 className="study-options-title">Explore Our Courses</h1>
      <p className="study-options-intro">
        Enhance your knowledge and skills in the financial markets with our comprehensive courses tailored to suit your interests and goals.
      </p>
      
      <div className="courses-container">
        <article className="course">
          <h2>Algorithmic Trading Principles</h2>
          <p>Master the art of algorithmic trading and gain a competitive edge in the market.</p>
          <ul className="course-details">
            <li>Understanding algorithmic trading concepts</li>
            <li>Implementing trading strategies using algorithms</li>
            <li>Backtesting and optimizing trading algorithms</li>
            <li>Risk management techniques in algorithmic trading</li>
          </ul>
        </article>

        <article className="course">
          <h2>Equity Trading Fundamentals</h2>
          <p>Build a strong foundation in equity trading and become proficient in stock market analysis.</p>
          <ul className="course-details">
            <li>Introduction to equity markets</li>
            <li>Technical and fundamental analysis of stocks</li>
            <li>Psychology of trading and decision-making</li>
            <li>Effective risk management strategies</li>
          </ul>
        </article>
        <article className="course">
          <h2>Global Indices Chart Reading</h2>
          <p>Decode the dynamics of global markets by mastering the art of reading and interpreting indices charts.</p>
          <ul className="course-details">
            <li>Understanding key global indices and their significance</li>
            <li>Analyzing chart patterns and trends in indices</li>
            <li>Identifying support and resistance levels</li>
            <li>Utilizing technical indicators for effective decision-making</li>
          </ul>
        </article>

        <article className="course">
          <h2>Cryptocurrency Fundamentals</h2>
          <p>Dive into the dynamic world of cryptocurrencies and blockchain technology, and unlock new opportunities in digital asset trading.</p>
          <ul className="course-details">
            <li>Introduction to cryptocurrencies and blockchain</li>
            <li>Understanding different types of cryptocurrencies</li>
            <li>Analyzing price charts and market trends in crypto</li>
            <li>Exploring trading strategies for cryptocurrencies</li>
          </ul>
        </article>

        

        {/* Repeat similar structure for other courses */}
      </div>
    </div>
  );
};

export default StudyOptions;
