import React from 'react';
import './Testimonials.css'; // Import CSS file for styling

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h1>Testimonials</h1>
      {/* Video Testimonial */}
      <div className="testimonial">
        <h2>Video Testimonial</h2>
        <div className="video-container">
          {/* Embed video from YouTube or any other platform */}
          <iframe
            width="400"
            height="200"
            src="https://www.youtube.com/embed/owwZCNfhkfY"
            title="Video Testimonial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      
      {/* Text Testimonial */}
      <div className="testimonial">
        <h2>Text Testimonial</h2>
        <p>
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ut felis eget
          odio laoreet feugiat. Nulla facilisi. Vestibulum in volutpat mauris, ac lacinia
          ex. Morbi vel nunc vitae justo vestibulum bibendum. Nullam hendrerit, est id
          vulputate tincidunt, sapien elit tristique velit."
        </p>
        <p>- John Doe</p>
      </div>
    </div>
  );
};

export default Testimonials;
