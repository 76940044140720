import React, { useState, useEffect } from 'react';
import './TestimonialCarousel.css';

const testimonials = [
  {
    name: "Hridaan Sharma",
    role: "Offline Course Student",
    content: "The most valuable aspect of Shivam Sir's training is the live trading sessions, where he demonstrates with real-time examples. This approach significantly boosted my confidence. I recently completed his course, and he comprehensively covered everything from the basics to advanced strategies. His practical knowledge is exceptional. I highly recommend joining his training sessions. He is a very genuine and knowledgeable trainer. Thank you, Sir!"
  },
  {
    name: "Anjali Rana",
    role: "Online Course Student",
    content: "I believe Shivam Sir is the best mentor I've ever had. Despite learning from many YouTube channels, I faced continuous losses. However, Shivam Sir's easy-to-understand teaching method transformed my trading approach. He taught me how to take trades and the reasons behind them clearly and effectively. Thanks to his guidance, almost 8 out of 10 of my trades are now profitable. Thank you, Sir, for making trading clear and successful for me."
  },
  {
    name: "Rahul Verma",
    role: "Offline Course Student",
    content: "Joining Rahar Stock Academy's offline course was the best decision I made for my trading career. The trainers are highly knowledgeable and provided in-depth insights into the market. I highly recommend this course to anyone in Chandigarh who wants to excel in trading."
  },
  {
    name: "Neha Singh",
    role: "Online Course Student",
    content: "I enrolled in Rahar Stock Academy's online course and it exceeded my expectations. The course material is comprehensive and easy to understand. The instructors are always available to clarify doubts. As an Indian residing in Mohali, I found this course perfect for enhancing my trading skills."
  }
  
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-carousel">
      {testimonials.map((testimonial, index) => (
        <div
          key={index}
          className={`testimonial ${index === currentIndex ? 'active' : ''}`}
        >
          <p className="testimonial-content">{testimonial.content}</p>
          <div className="testimonial-author">
            <p className="testimonial-name">{testimonial.name}</p>
            <p className="testimonial-role">{testimonial.role}</p>
          </div>
        </div>
      ))}
      <div className="carousel-dots">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default TestimonialCarousel;
