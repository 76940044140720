import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import './ContactPage.css';
import Header from './Header';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    console.log('ContactPage rendered');
  }, []);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log('Form Data:', formData);
    try {
      const response = await fetch('https://rsma-3.onrender.com/sendmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log('Response:', responseData);
      setIsSubmitted(true);
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (err) {
      console.error('Fetch Error:', err.message);
      alert('Failed to send message');
    }
  };

  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <>
    <Header/>
    <motion.div
      className="contact-page"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="contact-header">
        <h1>Connect with Rahar Stock Market Academy</h1>
        <p>Your Gateway to Financial Excellence</p>
      </div>
      <div className="contact-content">
        <motion.div 
          className="contact-info"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          <h2>Let's Start Your Journey</h2>
          <p>Ready to elevate your financial knowledge and investment strategies? We're here to guide you every step of the way. Reach out to us for personalized advice, course information, or any queries you might have.</p>
          <div className="contact-details">
            <div><FaPhone /> <a href="tel:+917508622302">+91 7508622302</a></div>
            <div><FaEnvelope /> <a href="mailto:shiivamrahhar@gmail.com">ShiivamRahhar@gmail.com</a></div>
            <div><FaMapMarkerAlt /> Sector 65, Mohali (Near Mohali Club)</div>
          </div>
          <div className="social-media-links">
            <motion.a href="https://wa.me/+917009362253" whileHover={{ scale: 1.2 }}><FaWhatsapp /></motion.a>
            <motion.a href="https://wa.me/+917009362253" whileHover={{ scale: 1.2 }}><FaTwitter /></motion.a>
            <motion.a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" whileHover={{ scale: 1.2 }}><FaYoutube/></motion.a>
          </div>
        </motion.div>
        <motion.div 
          className="contact-form"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          {isSubmitted ? (
            <div className="success-message">
              <h2>Thank You!</h2>
              <p>Your message has been successfully sent. We'll get back to you shortly.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <motion.input 
                whileFocus={{ scale: 1.05 }}
                type="text" 
                name="name" 
                placeholder="Your Name" 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
              <motion.input 
                whileFocus={{ scale: 1.05 }}
                type="email" 
                name="email" 
                placeholder="Your Email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
              <motion.input 
                whileFocus={{ scale: 1.05 }}
                type="text" 
                name="subject" 
                placeholder="Subject" 
                value={formData.subject} 
                onChange={handleChange} 
                required 
              />
              <motion.textarea 
                whileFocus={{ scale: 1.05 }}
                name="message" 
                placeholder="Your Message" 
                value={formData.message} 
                onChange={handleChange} 
                required
              ></motion.textarea>
              <motion.button 
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Send Message
              </motion.button>
            </form>
          )}
        </motion.div>
      </div>
    </motion.div>
    </>
  );
};

export default ContactPage;
