import React, { useState } from 'react';
import './Services.css';
import { FaChartLine, FaBookOpen, FaUserTie, FaLaptop, FaUsers, FaCertificate } from 'react-icons/fa';
import Header from './Header';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ServiceCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
   {/*  <button className="learn-more">Explore</button> */}
  </div>
);

const Services = () => {
  const [activeTab, setActiveTab] = useState('courses');

  const services = {
    courses: [
      { icon: <FaChartLine className="service-icon" />, title: "Technical Analysis Mastery", description: "Master chart patterns, indicators, and advanced trading strategies." },
      { icon: <FaBookOpen className="service-icon" />, title: "Fundamental Analysis Deep Dive", description: "Learn to analyze financial statements and value companies like a pro." },
      { icon: <FaLaptop className="service-icon" />, title: "Algo Trading Revolution", description: "Dive into the world of algorithmic trading with hands-on MT5 coding." },
    ],
    programs: [
      { icon: <FaUserTie className="service-icon" />, title: "Intraday Trading Bootcamp", description: "Intensive program to master the art of day trading in volatile markets." },
      { icon: <FaChartLine className="service-icon" />, title: "Swing Trading Strategies", description: "Capitalize on medium-term market trends with our proven strategies." },
      { icon: <FaUsers className="service-icon" />, title: "Group Mentorship Program", description: "Join a community of traders for shared learning and growth." },
    ],
    resources: [
      { icon: <FaLaptop className="service-icon" />, title: "Interactive Learning Platform", description: "Access video tutorials, e-books, and quizzes at your own pace." },
      { icon: <FaCertificate className="service-icon" />, title: "Professional Certifications", description: "Earn industry-recognized certifications to boost your credibility." },
      { icon: <FaUsers className="service-icon" />, title: "Trader's Network", description: "Connect with fellow traders in our exclusive online community." },
    ],
  };

  return (
    <>
    <Header/>
    <div className="services-container">
      <h2 className="services-title">Elevate Your Trading Journey</h2>
      <div className="services-tabs">
        <button className={activeTab === 'courses' ? 'active' : ''} onClick={() => setActiveTab('courses')}>Courses</button>
        <button className={activeTab === 'programs' ? 'active' : ''} onClick={() => setActiveTab('programs')}>Programs</button>
        <button className={activeTab === 'resources' ? 'active' : ''} onClick={() => setActiveTab('resources')}>Resources</button>
      </div>
      <div className="services-grid">
        {services[activeTab].map((service, index) => (
          <ServiceCard 
            key={`${activeTab}-${index}`} 
            icon={service.icon}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
      <div className="why-choose-us">
        <h3>Why Choose Rahar Stock Market Academy?</h3>
        <div className="reasons-grid">
          <div className="reason-card">
            <FaUserTie className="reason-icon" />
            <h4>Expert Mentors</h4>
            <p>Learn from seasoned traders with years of market experience</p>
          </div>
          <div className="reason-card">
            <FaLaptop className="reason-icon" />
            <h4>Live Trading Sessions</h4>
            <p>Participate in real-time market analysis and trading decisions</p>
          </div>
          <div className="reason-card">
            <FaUsers className="reason-icon" />
            <h4>Supportive Community</h4>
            <p>Join a network of traders for continuous learning and growth</p>
          </div>
        </div>
      </div>
      <div className="cta-section">
        <h3>Ready to Transform Your Trading?</h3>
        <p>Join thousands of successful traders who have elevated their skills with us.</p>
        <button className="cta-button"> <Link to="/contact">Start Your Journey Today</Link></button>
      </div>
    </div>
    </>
  );
};

export default Services;