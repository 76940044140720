import React, { useState, useEffect } from 'react';
import './MessagesPage.css'; // Import the CSS file
import { FaTrash, FaArrowRight } from 'react-icons/fa'; // Import trash and arrow right icons from react-icons library

const MessagesPage = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalReviewedMessages, setTotalReviewedMessages] = useState(0);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://rsma-3.onrender.com/messages');
        if (response.ok) {
          const data = await response.json();
          setMessages(data);
          setLoading(false);
          setTotalMessages(data.length);
          const reviewedMessages = data.filter(message => message.reviewed);
          setTotalReviewedMessages(reviewedMessages.length);
        } else {
          throw new Error('Failed to fetch messages');
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://rsma-3.onrender.com/messages/${id}`, {
        method: 'DELETE'
      });
      if (response.ok) {
        setMessages(messages.filter(message => message._id !== id));
      } else {
        throw new Error('Failed to delete message');
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleReviewed = async (id) => {
    try {
      const response = await fetch(`https://rsma-3.onrender.com/messages/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reviewed: true })
      });
      if (response.ok) {
        const updatedMessages = messages.map(message => {
          if (message._id === id) {
            return { ...message, reviewed: true };
          }
          return message;
        });
        setMessages(updatedMessages);
        setTotalReviewedMessages(totalReviewedMessages + 1);
      } else {
        throw new Error('Failed to mark message as reviewed');
      }
    } catch (error) {
      console.error('Error marking message as reviewed:', error);
    }
  };

  const handleAdminComment = (id, comment) => {
    // Implement functionality to submit admin comment to the server
    console.log(`Admin comment for message ${id}: ${comment}`);
  };

  const handleTakeAction = (id) => {
    setSelectedMessage(id === selectedMessage ? null : id);
  };

  return (
    <div className="messages-container">
      <h1 className="messages-heading">Messages</h1>
      <div className="message-stats">
        <button className="total-messages-button">Total Messages: {totalMessages}</button>
        <button className="reviewed-messages-button">Reviewed Messages: {totalReviewedMessages}</button>
      </div>
      {loading ? (
        <p className="loading-message">Loading messages...</p>
      ) : (
        <table className="messages-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message, index) => (
              <tr key={message._id}>
                <td>{index + 1}</td>
                <td>{message.name}</td>
                <td>{message.email}</td>
                <td>{message.subject}</td>
                <td>{message.message}</td>
                <td>
                  <div className="action-icons">
                    <FaTrash onClick={() => handleDelete(message._id)} className="action-icon red" />
                    <FaArrowRight onClick={() => handleReviewed(message._id)} className="action-icon green" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MessagesPage;
