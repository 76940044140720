import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import './Syllabus.css';
import Header from './Header';
import Footer from './Footer';

const syllabusData = [
  {
    title: "Introduction to Share Markets",
    modules: [
      {
        name: "Module 1: Introduction to Share Markets",
        topics: [
          "Understanding financial markets and their significance",
          "Differentiating between primary and secondary markets",
          "Exploring major stock exchanges and their role"
        ]
      },
      {
        name: "Module 2: Stock Market Participants",
        topics: [
          "Identifying key players: investors, traders, brokers, market makers, etc.",
          "Understanding their roles and interactions within the market ecosystem",
          "Analyzing the impact of institutional investors on market dynamics"
        ]
      },
      {
        name: "Module 3: Types of Financial Instruments",
        topics: [
          "Distinguishing between stocks, bonds, derivatives, and other investment vehicles",
          "Exploring the risk and return profiles of different instruments",
          "Understanding how various instruments affect the overall market"
        ]
      },
      {
        name: "Module 4: Market Indices and Benchmarks",
        topics: [
          "Introduction to market indices (e.g., S&P 500, NASDAQ)",
          "Exploring sectoral indices and their relevance",
          "Understanding how indices are calculated and their significance for investors"
        ]
      },
      {
        name: "Module 5: Fundamental Analysis",
        topics: [
          "Analyzing financial statements: balance sheet, income statement, cash flow statement",
          "Evaluating financial ratios and their interpretation",
          "Using fundamental analysis to assess the health and value of a company"
        ]
      },
      {
        name: "Module 6: Economic Indicators and Market News",
        topics: [
          "Identifying key economic indicators (GDP, inflation, unemployment)",
          "Understanding how economic events impact stock market trends",
          "Analyzing news and its impact on investor sentiment"
        ]
      },
      {
        name: "Module 7: Investment Strategies",
        topics: [
          "Value investing: principles and methods",
          "Growth investing: identifying growth prospects in stocks",
          "Dividend investing and income strategies",
          "Assessing risk tolerance and aligning strategies with investor goals"
        ]
      }
    ]
  },
  {
    title: "Technical Analysis and Trading Techniques",
    modules: [
      {
        name: "Module 8: Introduction to Technical Analysis",
        topics: [
          "Principles of technical analysis and its importance",
          "Understanding price charts: line charts, bar charts, candlestick charts",
          "Exploring chart patterns and their significance"
        ]
      },
      {
        name: "Module 9: Common Technical Indicators",
        topics: [
          "Moving averages: types and interpretation",
          "Relative Strength Index (RSI) and its applications",
          "Moving Average Convergence Divergence (MACD) indicator",
          "Bollinger Bands and other volatility indicators"
        ]
      },
      {
        name: "Module 10: Chart Patterns and Trends",
        topics: [
          "Recognizing common chart patterns: head and shoulders, double tops/bottoms, triangles, etc.",
          "Identifying trends: uptrend, downtrend, sideways movement",
          "Using trendlines and channels to analyze price movement"
        ]
      },
      {
        name: "Module 11: Support and Resistance Levels",
        topics: [
          "Understanding support and resistance as key concepts",
          "Identifying and drawing support/resistance levels",
          "Using support/resistance levels for entry and exit points"
        ]
      },
      {
        name: "Module 12: Candlestick Patterns and Analysis",
        topics: [
          "Introduction to candlestick charting",
          "Interpretation of candlestick patterns: doji, hammer, shooting star, engulfing patterns, etc.",
          "Incorporating candlestick patterns into trading decisions"
        ]
      },
      {
        name: "Module 13: Technical Analysis Strategies",
        topics: [
          "Swing trading vs. day trading vs. position trading",
          "Developing trading plans based on technical analysis",
          "Integrating fundamental and technical analysis for comprehensive decisions"
        ]
      },
      {
        name: "Module 14: Risk Management and Trading Psychology",
        topics: [
          "Importance of risk management in trading",
          "Dealing with emotions and biases in trading decisions",
          "Developing discipline and resilience as a trader"
        ]
      },
      {
        name: "Module 15: Trading Platforms and Tools",
        topics: [
          "Exploring trading platforms and their features",
          "Using online tools for technical analysis",
          "Real-time data feeds and their significance"
        ]
      },
      {
        name: "Module 16: Case Studies and Practical Application",
        topics: [
          "Analyzing real-world trading scenarios using technical analysis",
          "Learning from historical market movements",
          "Applying technical analysis concepts to different market situations"
        ]
      }
    ]
  },
  {
    title: "Options Trading",
    modules: [
      {
        name: "Module 1: Introduction to Options",
        topics: [
          "Understanding what options are and how they work",
          "Differences between call and put options",
          "Exploring option terminology: strike price, expiration date, premium, etc."
        ]
      },
      {
        name: "Module 2: Basics of Option Trading",
        topics: [
          "Role of options in risk management and speculation",
          "Options vs. stocks: advantages and limitations",
          "Key factors influencing option prices: intrinsic value, time value, volatility"
        ]
      },
      {
        name: "Module 3: Option Market Participants",
        topics: [
          "Identifying the different participants in the options market",
          "Understanding the roles of buyers, sellers, and market makers",
          "Impact of institutional investors on options trading"
        ]
      },
      {
        name: "Module 4: Option Pricing Models",
        topics: [
          "Overview of the Black-Scholes model and other pricing methods",
          "Factors affecting option prices: underlying stock price, strike price, time to expiration, volatility, interest rates",
          "Calculating option values and understanding the Greeks (delta, gamma, theta, vega)"
        ]
      },
      {
        name: "Module 5: Basic Option Buying Strategies",
        topics: [
          "Long call options: directional bets and leverage",
          "Long put options: hedging and speculative opportunities",
          "Breakeven points and profit potential for each strategy"
        ]
      },
      {
        name: "Module 6: Advanced Option Buying Strategies",
        topics: [
          "Protective put strategy: insuring a stock portfolio",
          "Covered call strategy: generating income from a stock position",
          "Diagonal spread and calendar spread: time-based strategies"
        ]
      },
      {
        name: "Module 7: Using Options for Speculation",
        topics: [
          "Leveraging options for short-term price movements",
          "Swing trading strategies using options",
          "Balancing risk and reward when speculating with options"
        ]
      },
      {
        name: "Module 8: Hedging and Risk Management",
        topics: [
          "Using options to hedge a stock portfolio",
          "Protective put vs. covered call as hedging techniques",
          "Designing a risk management plan using options"
        ]
      },
      {
        name: "Module 9: Volatility Trading with Options",
        topics: [
          "Volatility as an asset class",
          "Straddle and strangle strategies: profiting from high volatility",
          "Iron condor and butterfly spreads: profiting from low volatility"
        ]
      },
      {
        name: "Module 10: Practical Application and Case Studies",
        topics: [
          "Analyzing real-world scenarios using option buying strategies",
          "Case studies of successful and unsuccessful trades",
          "Simulated trading exercises to apply course concepts"
        ]
      },
      {
        name: "Module 11: Building a Diversified Portfolio with Options",
        topics: [
          "Incorporating options into a diversified investment portfolio",
          "Risk considerations when using options alongside other assets",
          "Aligning options strategies with investment goals"
        ]
      }
    ]
  }
];

const Syllabus = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [completedTopics, setCompletedTopics] = useState({});
  const [expandedModules, setExpandedModules] = useState({});

  
  useEffect(() => {
    const stored = localStorage.getItem('completedTopics');
    if (stored) {
      setCompletedTopics(JSON.parse(stored));
    }
  }, []);

  const toggleTopic = (sectionIndex, moduleIndex, topicIndex) => {
    const newCompleted = { ...completedTopics };
    if (!newCompleted[sectionIndex]) newCompleted[sectionIndex] = {};
    if (!newCompleted[sectionIndex][moduleIndex]) newCompleted[sectionIndex][moduleIndex] = {};
    newCompleted[sectionIndex][moduleIndex][topicIndex] = !newCompleted[sectionIndex][moduleIndex][topicIndex];
    setCompletedTopics(newCompleted);
    localStorage.setItem('completedTopics', JSON.stringify(newCompleted));

    if (Object.values(newCompleted[sectionIndex][moduleIndex]).filter(Boolean).length === 
        syllabusData[sectionIndex].modules[moduleIndex].topics.length) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    }
  };

  const toggleModule = (sectionIndex, moduleIndex) => {
    setExpandedModules(prev => ({
      ...prev,
      [sectionIndex]: {
        ...prev[sectionIndex],
        [moduleIndex]: !prev[sectionIndex]?.[moduleIndex]
      }
    }));
  };

  const progress = (sectionIndex) => {
    const section = completedTopics[sectionIndex] || {};
    const totalTopics = syllabusData[sectionIndex].modules.reduce((sum, module) => sum + module.topics.length, 0);
    const completedCount = Object.values(section).reduce((sum, module) => 
      sum + Object.values(module).filter(Boolean).length, 0);
    return (completedCount / totalTopics) * 100;
  };

  return (
    <>
    <Header/>
    <div className="syllabus-container">
      <h1 className="main-title">Trading Mastery Course</h1>
      
      <div className="section-carousel">
        {syllabusData.map((section, sectionIndex) => (
          <motion.div
            key={sectionIndex}
            className={`section-card ${activeSection === sectionIndex ? 'active' : ''}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveSection(sectionIndex)}
          >
            <h2>{section.title}</h2>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress(sectionIndex)}%` }}></div>
            </div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, rotateY: -90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: 90 }}
          transition={{ duration: 0.5 }}
          className="chapter-list"
        >
          {syllabusData[activeSection].modules.map((module, moduleIndex) => (
            <div key={moduleIndex} className="module-container">
              <h3 
                className="module-title" 
                onClick={() => toggleModule(activeSection, moduleIndex)}
              >
                {module.name}
                <span className="expand-icon">
                  {expandedModules[activeSection]?.[moduleIndex] ? '▼' : '▶'}
                </span>
              </h3>
              <AnimatePresence>
                {expandedModules[activeSection]?.[moduleIndex] && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {module.topics.map((topic, topicIndex) => (
                      <motion.div
                        key={topicIndex}
                        className={`chapter-item ${completedTopics[activeSection]?.[moduleIndex]?.[topicIndex] ? 'completed' : ''}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: topicIndex * 0.05 }}
                        whileHover={{ scale: 1.02 }}
                      >
                        <h4>• {topic}</h4>
                        <button onClick={() => toggleTopic(activeSection, moduleIndex, topicIndex)}>
                          {completedTopics[activeSection]?.[moduleIndex]?.[topicIndex] ? 'Completed' : 'Mark as Complete'}
                        </button>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </motion.div>
      </AnimatePresence>
     
    </div>
    <Footer/>
    </>
  );
};

export default Syllabus;