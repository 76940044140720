// WhatsAppButton.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  // Replace 'YOUR_PHONE_NUMBER' with your actual phone number
  const phoneNumber = '+9170093622302';
  const message = 'Hello, I have a question.';

  // Construct the WhatsApp message URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="whatsapp-button-container">
     
      <a href={whatsappUrl} className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="whatsapp-icon" />
      </a>
    </div>
  );
};

export default WhatsAppButton;
